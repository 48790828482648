// JS Dependencies: Popper, Bootstrap & JQuery
// import "jquery";
import "@popperjs/core";
import "bootstrap";

import "jquery-validation";
import "jquery-validation-unobtrusive";

// import "knockout-mapping";

import "toastr/toastr";

require("jquery-ui");

// CSS Dependencies
import "bootstrap/dist/css/bootstrap.css";
import "../../css/font-awesome.css";

function importAll(r) {
  r.keys().forEach(r);
}

importAll(require.context("../../fonts/", true, /\*/));
import "../../css/toastr.css";

import "../autoBinder/autoBinder";

// import "../shared/**/*.js";
importAll(require.context("../shared/", true, /\.js$/));

// import "../customBindings/*.js";
importAll(require.context("../customBindings/", true, /\.js$/));

import "../modules/admin/groups";
import "../shared/startup";

// Custom CSS imports
importAll(require.context("../../css/modules/shared/", true, /\.css$/));
import "../../fonts/opensans.css";

console.log("The 'site' bundle has been loaded!");
