﻿$(function() {
    var self = {};
    $("[data-toggle][data-toggle='tooltip']").each(function () {
        $(this).tooltip();
    });

    self.headerHeight = function() {
        try {
            var header = $("header nav")[0];
            var h = header.offsetHeight;
            return h;
        } catch (e) {
            return 0;
        }
    };
    self.footerHeight = function() {
        try {
            var footer = $("footer")[0];
            var f = footer.offsetHeight || 0;
            return f;
        } catch (e) {
            return 0;
        }
    };
    $("content").css("top", self.headerHeight());
    $("content").css("bottom", self.footerHeight());
});

$(document).ready(function() {

    $.fn.serializeObject = function() {
        var o = {};
        var a = this.serializeArray();
        $.each(a, function() {
            if (o[this.name] !== undefined) {
                if (!o[this.name].push) {
                    o[this.name] = [o[this.name]];
                }
                o[this.name].push(this.value || '');
            } else {
                o[this.name] = this.value || '';
            }
        });
        return o;
    };

    var i;
    var j;
    var a = false;
    var b = false;
    var c = false;
    var d = false;
    //Internal page dropdowns
    $('.dropdown').mouseover(function() {
        b = true;
        var item = $(this).attr("id");
        i = ".drop-" + item;
        $(i).addClass('show').removeClass('hide');
    });
    $('.dropdown').mouseleave(function() {
        b = false;
        if (a == false && b == false) {
            $(i).addClass('hide').removeClass('show');
        };
    });
    $('.dropdown-box').mouseenter(function() {
        a = true;
    });
    $('.dropdown-box').mouseleave(function() {
        if (a == true && b == true) {
            $(i).addClass('hide').removeClass('show');
            a = false;
        }
    });
    //Condensed nav dropdown
    $('.main-dropdown').mouseover(function() {
        d = true;
        var item = $(this).attr("id");
        j = ".main-drop-" + item;
        $(j).addClass('show').removeClass('hide');
    });
    $('.main-dropdown').mouseleave(function() {
        d = false;
        if (c == false && d == false) {
            $(j).addClass('hide').removeClass('show');
        };
    });
    $('.main-dropdown-box').mouseenter(function() {
        c = true;
    });
    $('.main-dropdown-box').mouseleave(function() {
        if (c == true && d == true) {
            $(j).addClass('hide').removeClass('show');
            c = false;
        }
    });
});