﻿//$(function () {
//    $('[data-toggle="tooltip"]').tooltip()
//})

//viewModels.navigation = function () {
//    var that = {};

//    that.init = function () {
//        //that.progressStyle = surveyServices().statusEnumToProgressBarStyle(survey.statusEnum);
//    };


//    return that;
//};