﻿import { hasDataAttribute, getDataAttribute } from "./utilities/data";
import "toastr/toastr";

if (!window.viewModels) {
  window.viewModels = {};
}

const convertToObservable = (source, target) => {
  for (var prop in source) {
    if (source.hasOwnProperty(prop)) {
      if (Array.isArray(source[prop])) {
        target[prop] = ko.observableArray([]);
        for (var i = 0; i < source[prop].length; i++) {
          var data = source[prop][i];
          var result = {};
          target[prop].push(convertToObservable(data, result));
        }
      } else if ($.isPlainObject(source[prop])) {
        target[prop] = {};
        target[prop] = ko.observable(
          convertToObservable(source[prop], target[prop])
        );
      } else {
        target[prop] = ko.observable(source[prop]);
      }
    }
  }

  return target;
};

const buildObservable = (viewModel) => {
  return convertToObservable(viewModel, {});
};

const extendToModel = (viewModel, isObservable, viewModelName) => {
  let viewModelToExtend;

  if (isObservable) {
    viewModelToExtend = buildObservable(viewModel);
  } else {
    viewModelToExtend = viewModel;
  }

  return extend(viewModelName, viewModelToExtend);
};

export const buildModel = (viewModel, isObservable) => {
  if (isObservable) {
    return buildObservable(viewModel);
  }

  return viewModel;
};

let models = [];
let counter = 0;

const skipDomNodeNames = Object.freeze([
  "SINGLEQUESTION",
  "QUESTIONNAV",
  "FILTER",
  "COMPARISONDETAILS",
  "GROUPQUESTION",
  "IMPERSONATE",
]);

const getContainerId = (container) => {
  var id = $(container).attr("id");
  if (id == null) {
    id = "autoBinder" + getNextId();
    $(container).attr("id", id);
  }
  return id;
};

const getNextId = () => {
  counter++;
  return counter;
};

const doesModelExist = (id) => {
  return models[id] != null;
};

const isNewModel = (container, id, model) => {
  var containerModel = ko.observable(model);
  models[id] = id;
  ko.applyBindings(containerModel, $(container)[0]);
};

const isExistingModel = (id, model) => {
  let existingModel = getModel(id);
  existingModel(model);
};

const getModel = (id) => {
  var model = ko.dataFor($("#" + id)[0]);
  return model;
};

const getData = async (url, domElement) => {
  let viewModel;

  try {
    viewModel = await $.get(url).promise();
  } catch (error) {
    console.error("Failed to fetch data.", error);
  }
  if (!viewModel) {
    return;
  }

  if (viewModel.error && viewModel.error.success == false) {
    toastr.error(viewModel.error.message);
    return;
  }

  if (!domElement || !domElement.length) {
    return;
  }

  let isSkip = skipDomNodeNames.indexOf(domElement[0].nodeName) !== -1;
  let isObservable = $(domElement).hasClass("observable");
  let extend = $(domElement).hasClass("extend");

  if (!isSkip) {
    let convertedViewModel;
    if (extend) {
      let viewModelName = getDataAttribute(domElement, "model");
      convertedViewModel = extendToModel(
        viewModel,
        isObservable,
        viewModelName
      );
    } else {
      convertedViewModel = buildModel(viewModel, isObservable);
    }

    let id = getContainerId(domElement);

    if (doesModelExist(id)) {
      isExistingModel(id, convertedViewModel);
    } else {
      isNewModel(domElement, id, convertedViewModel);
    }
  }

  $(domElement).find(".hide-binding").fadeIn();
};

const extend = (viewModelName, viewModel) => {
  if (viewModelName) {
    let extendedViewModel = viewModels[viewModelName]();

    if (Array.isArray(viewModel)) {
      extendedViewModel.data = viewModel;
    } else {
      for (var prop in viewModel) {
        if (viewModel.hasOwnProperty(prop)) {
          extendedViewModel[prop] = viewModel[prop];
        }
      }
      if (extendedViewModel.init != null) {
        extendedViewModel.init();
      }
    }
    return extendedViewModel;
  }
};

$(function () {
  let container = $("body");
  let bindableElements = $(container).find(".bindable");

  for (var i = 0; i < bindableElements.length; i++) {
    let data = bindableElements[i];
    let hideElement = $(data).find(".hide-binding");
    $(hideElement).hide();
    let url = "";
    let domElement = $(data);
    if (hasDataAttribute(domElement, "url")) {
      url += getDataAttribute(domElement, "url");
    }

    if (url === "") {
      return;
    }

    getData(url, domElement);
  }
});
