﻿export const hasDataAttribute = (domElement, attribute) => {
  var has = $(domElement).data(attribute);
  return typeof has != "undefined";
};

export const getDataAttribute = (domElement, attribute) => {
  if (hasDataAttribute(domElement, attribute)) {
    return $(domElement).data(attribute);
  }
  //throw new Error("DOM Element does not contain data attribute : " + attribute);
};
