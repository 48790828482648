﻿(function (window, startup, $) {
    $(function () {
        if (window.location.href.indexOf("Impersonate") <= 0) {
            $.ajax({
                type: 'POST',
                url: "/Home/CheckButtonDisplay/",
                dataType: 'json',
                success: function (data) {
                    if (data.Show) {
                        $("#testDisplay").css("visibility", "visible");

                        $(function () {
                            $("#testDisplay").bind('click', function () {
                                $.ajax({
                                    type: 'GET',
                                    url: "/Group/ClearGroup",
                                    success: function () {
                                        window.location.href = '/home';
                                    }
                                });
                            });
                        });
                    }
                },
                error: function (x, y, error) {
                    toastr.error(error);
                }
            });
            $.ajax({
                type: 'POST',
                url: "/Home/WhereAmI",
                dataType: 'json',
                success: function (data) {
                    $('#whereami').html(data.Message);
                },
                error: function (x, y, error) {
                    toastr.error(error);
                }
            });
        }
    });
})(window, window.startup = window.startup || {}, jQuery);