﻿surveyServices = function() {
    var that = {};

    that.isAvailable = function(statusEnum) {
        return statusEnum != 0;
    };

    that.statusEnumToProgressBarStyle = function(statusEnum) {
        if (statusEnum == 0) {
            return "";
        }

        if (statusEnum == 1) {
            return "progress-bar-info";
        }

        if (statusEnum == 2) {
            return "progress-bar-success";
        }

        if (statusEnum == 3) {
            return "progress-bar-danger";
        }
    };

    return that;
}