﻿import util from "../shared/binding";

const disclaimer = function () {
  var that = {};
  var self = {};

  that.showDisclaimer = function (resultsUrl, surveyId) {
    $("#terms-url").val(resultsUrl);
    $("#survey-id").val(surveyId);
    $("#terms-modal").modal("show");
  };

  that.redirectToResults = function (resultsUrl) {
    window.location = resultsUrl;
  };

  util.safeBind("body", ".to-results", "click", function () {
    var url = $(this).data("results");
    var surveyId = $(this).data("surveyid");

    $.get("/api/Survey/" + surveyId + "/Disclaimer", function (result) {
      if (result) {
        that.showDisclaimer(url, surveyId);
      } else {
        that.redirectToResults(url);
      }
    }).fail(function () {
      that.showDisclaimer(url, surveyId);
    });
  });

  util.safeBind("disclaimer", ".terms-agree", "click", function () {
    var url = $("#terms-url").val();
    var surveyId = $("#survey-id").val();
    $.post("/api/Survey/" + surveyId + "/Disclaimer", null, function (result) {
      $("#").modal("hide");
      that.redirectToResults(url);
    });
  });

  return that;
};

$(function () {
  disclaimer();
});
