﻿//$(function() {
//    util.safeBind("impersonate", ".user-row", "click", function() {
//        var userId = $(this).data('id');
//        $("#login").val(userId);

//    });
//});
(function (window, impersonate, $) {
    impersonate.UsersViewModel = function (data) {
        if (data) {
            var self = this;

            self.users = ko.observableArray([]);

            for (var i = 0; i < data.length; i++) {
                self.users.push(new impersonate.UserViewModel(data[i]));
            }
        }
    };

    impersonate.UserViewModel = function (data) {
        if (data) {
            var self = this;

            ko.mapping.fromJS(data, {}, self);

            self.impersonation = function (data, event) {
                event.preventDefault();

                $("#login").val(data.id());
            };
        }
    };

    impersonate.viewModel = {};

    $(function () {
        var element = document.getElementById('impersonate');

        if (element) {
            $.ajax({
                type: 'GET',
                dataType: 'json',
                url: '/api/Impersonate',
                success: function (result) {
                    if (result) {
                        impersonate.viewModel = new impersonate.UsersViewModel(result);
                        ko.applyBindings(impersonate.viewModel, element);
                }
            },
                error: function (x, y, error) {
                    toastr.error(error);
                }
        });
    }
    });
})(window, window.impersonate = window.impersonate || {}, jQuery);