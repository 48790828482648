﻿(function (window, groups, $) {
    groups.groupsViewModel = function (data) {
        var self = this;

        ko.mapping.fromJS(data, {}, self);

        self.showGroups = ko.computed(function (item) {
            if (self.facilities && self.facilities()) {
                return self.facilities().length > 0;
            }
        });

        self.onChange = function (item, event) {
            event.preventDefault();
            var myURL = "/Group/";
            if (self.currentFacility()) {
                myURL = myURL + self.currentFacility().toString();
            } else {
                myURL = myURL + '/ClearGroup';
            }
            //pass your variable to the jQuery ajax call
            $.ajax({ url: myURL, type: 'GET' }).done(function (data) {
                window.location.href = "/Home";
                //this line of code would never be called because the browser has navigated away from this page...
                $('#export').html(data);
            }).fail(function (data) {
                toastr.warn('Could not export data, please contact LGL.');
            });
        };
    };

    groups.viewModel = {};

    $(function () {
        var url = $('input[id="groupsUrl"]').val();
        if (url) {
            $.ajax({
                url: url,
                success: function (data) {
                    if (data) {
                        var element = document.getElementById('groups');
                        groups.viewModel = new groups.groupsViewModel(data);
                        ko.applyBindings(groups.viewModel, element);
                    }
                },
                error: function (x, y, error) {
                    alert(error);
                }
            });
        }
    });
})(window, window.groups = window.groups || {}, jQuery);